import { ConfigNameRoutes, routesUrl } from './constants'

import { lazy } from 'react'

export const routes = [
  {
    path: routesUrl.base,
    exact: true,
    redirect: routesUrl.login
  },
  {
    path: routesUrl.baseAuth,
    component: lazy(() => import('containers/AuthContainer/AuthContainer')),
    exact: false,
    private: false,
    routes: [
      {
        path: routesUrl.login,
        component: lazy(() => import('pages/LoginPage/LoginPage')),
        exact: true,
        private: false
      }
    ]
  },
  {
    path: routesUrl.baseApp,
    component: lazy(() => import('containers/AppContainer/index')),
    exact: false,
    private: true,
    requiredPermissions: false,
    routes: [
      {
        path: routesUrl.home,
        component: lazy(() => import('pages/HomeTraining/HomeTrainingPlans')),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // //! Form New TEMPORAL ROUTE
      {
        path: '/form/new/',
        component: lazy(() =>
          import('../components/Common/ModuleForms/Form/FromManage')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      //* CALENDAR JOURNEY
      {
        path: `${ConfigNameRoutes.calendarJourney}`,
        component: lazy(() =>
          import('components/Common/ModuleCalendarJourney/CalendarJourney')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* AFILIATES
      {
        path: `${ConfigNameRoutes.generalList}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/ListGeneral')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* BlackList
      {
        path: `${ConfigNameRoutes.BlackList}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/BlackList')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Lista Inscripciones métodos de pago
      {
        path: `${ConfigNameRoutes.TokenizationList}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/TokenizationList')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // FLOW
      {
        path: `${ConfigNameRoutes.flow}`,
        component: lazy(() => import('components/Common/ModuleFlow/Flows')),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: '/flow-comercial/:comercial_id',
        component: lazy(() =>
          import('components/Common/ModuleFlow/ComercialFlow')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: '/flow-comercial/:comercial_id/:deal_uuid',
        component: lazy(() =>
          import('components/Common/ModuleFlow/DetailFlowItem')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // MODULE SHOPPING EXPRESS
      {
        path: `${ConfigNameRoutes.shoppingExpress}`,
        component: lazy(() => import('components/Common/ModuleShoppingExpress/ShoppingExpress')),
        exact: true,
        private: true,
        requiredPermissions: true,
      },
      {
        path: `${ConfigNameRoutes.createShoppingExpress}`,
        component: lazy(() => import('components/Common/ModuleShoppingExpress/CreateShoppingExpress')),
        exact: true,
        private: true,
        requiredPermissions: false,
      },
      {
        path: `${ConfigNameRoutes.shoppingExpressId}`,
        component: lazy(() => import('components/Common/ModuleShoppingExpress/ById/ShoppingExpressId')),
        exact: true,
        private: true,
        requiredPermissions: false,
      },

      // MODULE PAYMENT SHIPMENT
      {
        path: `${ConfigNameRoutes.paymentShipment}`,
        component: lazy(() => import('components/Common/ModulePaymentShipment/ListPaymentShipment')),
        exact: true,
        private: true,
        requiredPermissions: true,
      },

      // DEAL FLOW
      {
        path: '/flow/deal-flow',
        component: lazy(() => import('components/Common/DealFlow/DealFlow')),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // * COUPONS
      {
        path: `${ConfigNameRoutes.coupons}`,
        component: lazy(() =>
          import('components/Common/ModuleCoupons/ListCoupons')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.coupons}/:id`,
        component: lazy(() =>
          import('components/Common/ModuleCoupons/LayoutBash/LayoutBash')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Calendar Reservation Coach
      {
        path: `${ConfigNameRoutes.trainerBooking}`,
        component: lazy(() => import('pages/AfiliatesPage/ReservationCoach')),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* VIRTUAL JOURNEY

      {
        path: `${ConfigNameRoutes.virtualAfiliates}`,
        component: lazy(() =>
          import('components/Common/ModuleVirtualJourney/VirtualAfiliatesList')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      {
        path: `${ConfigNameRoutes.detailVirtualAfiliate}`,
        component: lazy(() =>
          import('components/Common/ModuleVirtualJourney/VirtualAfiliateDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* DASHBOARD JOURNEY
      {
        path: `${ConfigNameRoutes.dashboardJourney}`,
        component: lazy(() =>
          import('components/Common/ModuleDashboardJourney/DashboardJourney')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* ADMIN TORRE CENTRAL ROLES ACTIVE AND INACTIVE
      {
        path: `${ConfigNameRoutes.partnersJourney}`,
        component: lazy(() =>
          import('components/Common/ModulePartnersJourney/PartnersJourney')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.partnersJourneyCoach}`,
        component: lazy(() =>
          import('components/Common/ModulePartnersJourney/PartnersJourney')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Quotes
      {
        path: `${ConfigNameRoutes.quotes}`,
        component: lazy(() =>
          import('../pages/ConfigModuleQuotes/ConfigModuleQuotesPage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Clinic History
      {
        path: '/clinic-history/:quote_id/:appoiment_type_id/:medical_professional_id/:user_id/:modality_id/:isLead',
        component: lazy(() =>
          import(
            '../components/Common/ModuleClinicalHistory/ClinicalHistory/DetailClinicalHistory'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Maximun Capacity
      {
        path: `${ConfigNameRoutes.maximunCapacity}`,
        component: lazy(() =>
          import('components/Common/ManageTrainingPlan/Capacity/Capacity')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Detail Afiliate
      {
        path: `${ConfigNameRoutes.generalList}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/ListGeneral')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // * General list affiliate
      {
        path: `${ConfigNameRoutes.afiliates}`,
        component: lazy(() => import('pages/AfiliatesPage/AfiliatesPage')),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.afiliatesTrainer}`,
        component: lazy(() => import('pages/AfiliatesPage/AfiliatesTrainer')),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // * General list medical leads
      {
        path: `${ConfigNameRoutes.medicalLeads}`,
        component: lazy(() => import('components/Common/ModuleMedical/List/Leads/Leads')),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.detailAfiliate}`,
        component: lazy(() =>
          import('pages/DetailAfiliatePage/DetailAfiliatePage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      //* Detail Afiliate Comercial
      {
        path: `${ConfigNameRoutes.detailAfiliateComercial}`,
        component: lazy(() =>
          import('pages/DetailAfiliatePage/DetailAfiliatePage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.detailAfiliateComercialItem}`,
        component: lazy(() =>
          import('pages/DetailAfiliatePage/DetailAfiliatePage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.detailAfiliateComercialItemUserType}`,
        component: lazy(() =>
          import('pages/DetailAfiliatePage/DetailAfiliatePage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //*HISTORIAL DE CITAS VALIDACION ES PREPAGADA
      {
        path: '/clinic-history/:quote_id/:appoiment_type_id/:medical_professional_id/:user_id/:modality_id/:isLead/:is_prepay',
        component: lazy(() =>
          import(
            '../components/Common/ModuleClinicalHistory/ClinicalHistory/DetailClinicalHistory'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Create Plan Training for Afiliate
      {
        path: '/create-plan-training-for-afiliate/:document_number',
        component: lazy(() =>
          import('pages/ManageTrainingConfigPage/ManageTrainingConfigPage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Manage Training
      {
        path: `${ConfigNameRoutes.manageTraining}`,
        component: lazy(() =>
          import('containers/ManageTrainingContainer/ManageTrainingContainer')
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.manageTraining}/exercises`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/Exercises/ListExercises'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.manageTraining}/sessions`,
            component: lazy(() =>
              import(
                'components/Common/ModuleSession/ListSessions/ListSessions'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.manageTraining}/plans-training`,
            component: lazy(() =>
              import('pages/TrainingsConfigPage/TrainingsConfigPage')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      //* users by trainer
      {
        path: '/partners-trainer/:trainer_id',
        component: lazy(() =>
          import(
            'components/Common/ManageTrainingPlan/ListUsersForTrainer/ListUsersForTrainer'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      //* Create Session
      {
        path: `${ConfigNameRoutes.createSession}`,
        component: lazy(() => import('pages/SessionesPage/SessionesPage')),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Update Session
      {
        path: `${ConfigNameRoutes.updateSession}`,
        component: lazy(() => import('pages/SessionesPage/SessionesPage')),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Quotation
      {
        path: `${ConfigNameRoutes.quotation}`,
        component: lazy(() =>
          import('components/Common/ModuleQuotations/ListQuotations')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: '/quotation-config/:id_user/:is_from_crm',
        component: lazy(() =>
          import(
            'components/Common/ModuleQuotations/QuotationConfig/QuotationConfig'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Quotation Config
      {
        path: `${ConfigNameRoutes.quotationConfig}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleQuotations/QuotationConfig/QuotationConfig'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // new quotation facturation
      {
        path: `${ConfigNameRoutes.facturation}`,
        component: lazy(() =>
          import('components/Common/ModuleQuotations/ListFacturation')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      //* Invoice config
      {
        path: `${ConfigNameRoutes.invoiceConfig}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleQuotations/QuotationConfig/InvoiceConfig'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      //* Create Training Plan
      {
        path: `${ConfigNameRoutes.createTraining}`,
        component: lazy(() =>
          import('pages/ManageTrainingConfigPage/ManageTrainingConfigPage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Create Training Plan For Afiliate
      {
        path: `${ConfigNameRoutes.createTrainingForAfiliate}`,
        component: lazy(() =>
          import('pages/ManageTrainingConfigPage/ManageTrainingConfigPage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Config Training Plan
      {
        path: `${ConfigNameRoutes.configTrainingPlan}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleTrainingContainer/ModuleTrainingContainer'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/MuscleGroups`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/MuscleGroups/ListMuscleGroups'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/Objectives`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/Objectives/ListObjectives'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/MedicalConditions`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/MedicalConditions/ListMedicalConditions'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/TrainingLevels`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/TrainingLevels/ListTrainingLevels'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/TrainingElements`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/TrainingElements/ListTrainingElements'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/TrainingSteps`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/TrainingSteps/ListTrainingSteps'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/TrainingPlaces`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/TrainingPlaces/ListTrainingPlaces'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTrainingPlan}/movements`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/List/Movements/ListMovements'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },
      //* Config Products
      {
        path: `${ConfigNameRoutes.configProducts}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleConfigProducts/Config/Products/ListProducts'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      {
        path: '/detail-config-products/:product_id/:option_value/:variant_id?',
        component: lazy(() =>
          import(
            'components/Common/ModuleConfigProducts/Config/Detail/GeneralDetailProduct'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      {
        path: '/detail-config-products/:product_id/:index_value?',
        component: lazy(() =>
          import(
            'components/Common/ModuleConfigProducts/Config/Detail/GeneralDetailProduct'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // products arrears
      {
        path: '/products-arrears/:user_id/:id_line/:payment_id',
        component: lazy(() =>
          import(
            'components/Common/ModuleConfigProducts/Config/Products/ProductsArrears/ProductsArrears'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      //* PROMOTIONS
      {
        path: '/promotions-grid/:promotion_id?',
        component: lazy(() =>
          import(
            'components/Common/ModulePromotions/PromotionGrid/PromotionGrid'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: '/promotions-stats/:promotion_id?',
        component: lazy(() =>
          import('components/Common/ModulePromotions/Stats/PromotionStats')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // Roulette
      {
        path: `${ConfigNameRoutes.configRoulette}`,
        component: lazy(() => import('components/Common/ModuleConfigRoulette')),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // payment-link
      {
        path: `${ConfigNameRoutes.paymentLink}`,
        component: lazy(() =>
          import('components/Common/ModulePaymentLink/ListPaymentLink')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // payment-link
      {
        path: `${ConfigNameRoutes.unformalizedPaymentLink}`,
        component: lazy(() =>
          import('components/Common/ModulePaymentLink/ListUnformalizedPaymentLink')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.paymentLink}/:uuid`,
        component: lazy(() =>
          import('components/Common/ModulePaymentLink/PaymentLinkDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // promotions
      {
        path: `${ConfigNameRoutes.promotions}`,
        component: lazy(() =>
          import('components/Common/ModulePromotions/ListPromotions')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.promotions}/:uuid`,
        component: lazy(() =>
          import('components/Common/ModulePromotions/PromotionDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.promotions}/:uuid/:page`,
        component: lazy(() =>
          import('components/Common/ModulePromotions/PromotionDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Carterization
      {
        path: `${ConfigNameRoutes.carterization}`,
        component: lazy(() =>
          import('components/Common/ModuleCarterization/Carterization')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Reports
      {
        path: `${ConfigNameRoutes.reports}`,
        component: lazy(() =>
          import('components/Common/ModuleMedicalReports/Reports')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* MODULE PRODUCTS
      {
        path: `${ConfigNameRoutes.products}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleConfigProducts/ModuleConfigProductsContainer'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.products}/channels`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Channels/ListChannels'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/currencies`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Currencies/ListCurrencies'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/taxes`,
            component: lazy(() =>
              import('components/Common/ModuleConfigProducts/Taxes/ListTaxes')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/commissions`,
            component: lazy(() =>
              import('components/Common/ModuleConfigProducts/Commissions')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/commissions-rules`,
            component: lazy(() =>
              import('components/Common/ModuleConfigProducts/CommissionsRules')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/segments`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Segments/ListSegments'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/durations`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Durations/ListDurations'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/goals`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/goals/ListGoals'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/terminals`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Terminals/ListTerminals'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/config-terminals`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/ConfigTerminals/ListConfigTerminals'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/product-categories`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Categories/ListCategories'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/contracts`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Contracts/ListContracts'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/issues-cause`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/IssuesCause/ListCausses'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/issues`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/IssuesMaster/ListIssues'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/configuration-referrals`,
            component: lazy(() =>
              import(
                'components/Common/ModuleReferralsHasProduct/ListConfigurationReferrals'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/documents`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Documentsall/ListDocuments'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/payments-type`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/PaymentsType/ListPaymentsType'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/franchises`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Franchises/ListFranchises'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/rating-reason`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/RatingReason/ListRatingReason'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/salesSource`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/SalesSource/ListSalesSource'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/products-appointments-types`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/ProductAppointmentsTypes/ListProductAppointmentsTypes'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.products}/radio`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Radio/ListRadio'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      //* MODULE RESERVATIONS
      {
        path: `${ConfigNameRoutes.collaborators}`,
        component: lazy(() =>
          import('components/Common/ModuleCollaborators/ListCollaborators')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      {
        path: '/collaborator/detail/:user_id',
        component: lazy(() =>
          import('../components/Common/ModuleCollaborators/DetailCollaborator')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      {
        path: `${ConfigNameRoutes.configReservations}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleConfigReservations/ModuleConfigReservationsContainer'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.configReservations}/attributes`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/Attributes/ListAttributes'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/activity-category`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/ActivityCategory/ListActivityCategory'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/activities`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/Activities/ListActivities'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/type-of-contract`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/TypeOfContract/ListTypeOfContract'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/location-category`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/LocationCategory/ListLocationCategory'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/location`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/Location/ListLocation'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/venues-category`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/VenuesCategory/ListVenuesCategory'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/venues`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/Venues/ListVenues'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/days-festives`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/Festives/ListFestives'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/blocking-reasons`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/ReasonBlock/ListReasonBlock'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/activities-cancelation-reason`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigReservations/ReasonActivityCancelation/ListReasonActivityCancelation'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/venue-zones`,
            component: lazy(() => import('components/Common/ModuleConfigReservations/VenueZones/Index')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/space-rules`,
            component: lazy(() => import('components/Common/ModuleConfigReservations/Spaces/ListSpaces')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configReservations}/training-absence`,
            component: lazy(() => import('components/Common/ModuleConfigReservations/TrainingAbsence/ListTrainingAbsence')),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      //* LOCATIONS
      {
        path: `${ConfigNameRoutes.locations}`,
        component: lazy(() =>
          import('components/Common/ModuleLocationsVenue/ListLocations')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* NUTRITION
      {
        path: `${ConfigNameRoutes.nutrition}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleNutrition/NutritionPage/NutritionPage'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* DIARY GENERAL
      {
        path: `${ConfigNameRoutes.diaryGeneral}`,
        component: lazy(() =>
          import('components/Common/ModuleQuotesGeneral/ModuleQuotesGeneral')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* ACTIVITIES GENERAL
      {
        path: `${ConfigNameRoutes.activitiesCalendar}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleActivitiesCalendar/ModuleActivitiesCalendar'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* Group Activity Detail
      {
        path: `${ConfigNameRoutes.groupActivityDetail}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleActivitiesCalendar/DetailActivityReservation/DetailActivityReservation'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* MODULE CONFIGURATIONS RECIPES

      {
        path: `${ConfigNameRoutes.recipes}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleNutritionContainer/ModuleNutritionContainer'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.recipes}/food`,
            component: lazy(() =>
              import('components/Common/ModuleMedical/List/Food/ListFood')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.recipes}/recipes`,
            component: lazy(() =>
              import('components/Common/ModuleRecipes/ListRecipes')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.recipes}/nutrition`,
            component: lazy(() =>
              import(
                'components/Common/ModuleNutrition/NutritionTemplates/ListNutritionTemplates'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.recipes}/type-food`,
            component: lazy(() =>
              import(
                'components/Common/ModuleMedical/List/TypeFood/ListTypeFood'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.recipes}/weight-unit`,
            component: lazy(() =>
              import(
                'components/Common/ModuleMedical/List/WeightUnit/ListWeightUnit'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.recipes}/suplements`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Supplements/ListSupplements'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      //* MODULE CONFIGURATIONS HISTORY CLINIC

      {
        path: `${ConfigNameRoutes.configClinicHistory}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleClinicHistoryContainer/ModuleClinicHistoryContainer'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.configClinicHistory}/BloodType`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/BloodType/ListBloodType'
              )
            ),
            title: 'Tipo de Sangre',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/GenderIdentity`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/GenderIdentity/ListGenderIdentity'
              )
            ),
            title: 'Identidad de genero',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/reasons-init-ch`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/ArgumentForce/ListArgumentForce'
              )
            ),
            title: 'Motivos de inicio de HC',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/method`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Method/ListMethod'
              )
            ),
            title: 'Método',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/mode-type`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/ModeType/ListModeType'
              )
            ),
            title: 'Tipo o modo',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/Occupation`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Occupation/ListOccupation'
              )
            ),
            title: 'Ocupación',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/relationship`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Relationship/ListRelationship'
              )
            ),
            title: 'Parentesco',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/oms-risk`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/OMSRisk/ListOSMRisk'
              )
            ),
            title: 'Riesgo OMS',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/physical-activity`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/PhysicalActivity/ListPhysicalActivity'
              )
            ),
            title: 'Actividad física',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/disability`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Disability/ListDisability'
              )
            ),
            title: 'Discapacidades',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/sleep-pattern`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/SleepPattern/ListSleepPattern'
              )
            ),
            title: 'Patrón del sueño',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/type-preparation`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/FoodPreparationType/ListFormPreparationType'
              )
            ),
            title: 'Tipo de preparación',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/type-diagnostic`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/DiagnosticType/ListDiagnosticType'
              )
            ),
            title: 'Tipo de diagnostico',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/modality`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Modality/ListModality'
              )
            ),
            title: 'Modalidad',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/link-types`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/LinkTypes/ListLinkTypes'
              )
            ),
            title: 'Tipo de vinculación',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/nutrition-goals`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/NutritionGoals/ListNutritionGoals'
              )
            ),
            title: 'Objetivos de nutrición',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/type-alimentation`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/TypeAlimentation/ListTypeAlimentation'
              )
            ),
            title: 'Tipos de alimentación',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/territorial-entity`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/TerritorialEntity/ListTerritorialEntity'
              )
            ),
            title: 'Entidad territorial',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/service-group`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/ServiceGroup/ListServiceGroup'
              )
            ),
            title: 'Grupos de servicio',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/system-gateway`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/SystemGateway/ListSystemGateway'
              )
            ),
            title: 'Puerta de entrada del sistema',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/place-attention`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/PlaceAttention/ListPlaceAttention'
              )
            ),
            title: 'Lugar de atención',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/health-technology`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/HealthTechnology/ListHealthTechnology'
              )
            ),
            title: 'Tecnología en salud',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/type-health-technology`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/TypeHealthTechnology/ListTypeHealthTechnology'
              )
            ),
            title: 'Tipo de tecnología en salud',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/eps`,
            component: lazy(() =>
              import('../components/Common/ModuleMedical/List/EPS/ListEPS')
            ),
            title: 'Entidad promotora de salud',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/procedures`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Procedure/ListProcedure'
              )
            ),
            title: 'Procedimientos',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/query-type`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/QueryType/ListQueryType'
              )
            ),
            title: 'Tipo de consulta',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/diagnosis`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Diagnosis/ListDiagnosis'
              )
            ),
            title: 'Diagnosticos',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/health-education`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/HealthEducation/ListHealthEducation'
              )
            ),
            title: 'Educación en salud',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/pathological-antecedents`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/PathologicalAntecedents/ListPathologicalAntecedents'
              )
            ),
            title: 'Antecedentes patológicos',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/ethnicity`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/Ethnicity/ListEthnicity'
              )
            ),
            title: 'Pertenencia etnica',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/reason-consultation`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/ReasonConsultation/ListReasonConsultation'
              )
            ),
            title: 'Motivos de consulta',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/territorial-zones`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/TerritorialZones/ListTerritorialZones'
              )
            ),
            title: 'Zonas Territoriales',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/cause-attention`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/CauseAttention/ListCauseAttention'
              )
            ),
            title: 'Causas de atención',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/family-history`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/FamilyHistory/ListFamilyHistory'
              )
            ),
            title: 'Antecedentes familiares',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/mycoach-intervention`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/MyCoachIntervention/ListMyCoachIntervention'
              )
            ),
            title: 'Intervención MyCoach',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/ethnic-community`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/EthnicCommunity/ListEthnicCommunity'
              )
            ),
            title: 'Comunidad etnica',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/musculoskeletal-history`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/MusculoskeletalHistory/ListMusculoskeletalHistory'
              )
            ),
            title: 'Antecedentes Osteomusculares',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/daily-food`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/DailyFood/ListDailyFood'
              )
            ),
            title: 'Tipos de comidas',
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configClinicHistory}/referral-services`,
            component: lazy(() =>
              import(
                '../components/Common/ModuleMedical/List/ReferralServices/ListReferralServices'
              )
            ),
            title: 'Servisios de remisión',
            exact: true,
            private: true,
            requiredPermissions: true
          }
          // {
          //   path: `${ConfigNameRoutes.configClinicHistory}/suplements`,
          //   component: lazy(() =>
          //     import(
          //       "../components/Common/ModuleMedical/List/Supplements/ListSupplements"
          //     )
          //   ),
          //   title: "Suplementos",
          //   exact: true,
          //   private: true,
          //   requiredPermissions: true,
          // },
        ]
      },

      //* MODULE CONFIGURATIONS

      {
        path: `${ConfigNameRoutes.config}`,
        component: lazy(() =>
          import('pages/ConfigModulesPage/ConfigModulesPage')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      //* CONFIGURATIONS MODULES
      {
        path: `${ConfigNameRoutes.configModules}`,
        component: lazy(() =>
          import('containers/Config/ModuleModules/ModuleModules')
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.configModules}/group-modules`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/GroupModules/ListGroupModules'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/modules`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Modules/ListModules')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/roles`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Roles/ListRoles')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/permissions`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/Permissions/ListPermissions'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/images`,
            component: lazy(() =>
              import('components/Common/ModuleImages/List/ListImages')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/users`,
            component: lazy(() =>
              import(
                'components/Common/ModuleProfessional/List/Proffesional/ListProffesional'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/countries`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigProducts/Countries/ListCountries'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/departments`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/Departments/ListDepartments'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/cities`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Cities/ListCities')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/regions`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Regions/ListRegions')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/localities`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Localities/ListLocalities')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/zones`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Zones/ListZones')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/organizations`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfig/Organizations/ListOrganizations'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/companies`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Companies/ListCompanies')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/brands`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Mark/ListMarks')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/document-types`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/DocumentTypes/ListDocumentTypes')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configModules}/restrictions`,
            component: lazy(() =>
              import('components/Common/ModuleConfig/Restrictions')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      // Module Journeys
      {
        path: `${ConfigNameRoutes.configJourneys}`,
        component: lazy(() =>
          import('containers/Config/ModuleJourneys/ModuleJourneys')
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.configJourneys}/objetive-virtual-product`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigJourney/ObjetiveVirtualProduct/ListObjetiveVirtualProduct'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configJourneys}/type-of-work`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigJourney/TypeTaskVirtualProduct/ListTypeTask'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configJourneys}/physical-condition`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigJourney/PhysicalCondition/ListPhysicalCondition'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configJourneys}/task-stage`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigJourney/StepsTaksVirtualProduct/ListTaskStage'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configJourneys}/elements-training-mycoach`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigJourney/TrainingElementsMycoach/ListTrainingElementsMycoach'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configJourneys}/heart-diseases`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigJourney/HeartDiseases/ListHeartDiseases'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configJourneys}/reasons-reasing-quotes`,
            component: lazy(() =>
              import(
                'components/Common/ModuleConfigJourney/ReasonsReasingQuotes/ListReasonsReasingQuotes'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      // Module Tickets
      {
        path: `${ConfigNameRoutes.tickets}`,
        component: lazy(() =>
          import('../components/Common/ModuleTickets/Tickets/Dashboard')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.tickets}/:uuid`,
        component: lazy(() =>
          import('../components/Common/ModuleTickets/Tickets/Dashboard')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.configTickets}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleConfigTickets/ModuleConfigTicketsContainer'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.configTickets}/categories`,
            component: lazy(() =>
              import('components/Common/ModuleTickets/Config/ListCategory')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTickets}/sub-categories`,
             component: lazy(() =>
              import("components/Common/ModuleTickets/Config/ListSubCategory")
            ),
             exact: true,
            private: true,
            requiredPermissions: true,
           },
          {
            path: `${ConfigNameRoutes.configTickets}/ranking`,
            component: lazy(() =>
              import('components/Common/ModuleTickets/Config/ListSeverity')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTickets}/type-ticket`,
            component: lazy(() =>
              import('components/Common/ModuleTickets/Config/ListTypeTicket')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTickets}/sources`,
            component: lazy(() =>
              import('components/Common/ModuleTickets/Config/ListChannel')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configTickets}/area`,
            component: lazy(() =>
              import('components/Common/ModuleTickets/Config/ListArea')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      // MODULO PERFILAMIENTO
      {
        path: `${ConfigNameRoutes.profiling}`,
        component: lazy(() =>
          import('components/Common/ModuleProfiling/ProfilingListWithFilters')
        ),
        exact: false,
        private: true,
        requiredPermissions: true
      },
      {
        path: '/create-profiling',
        component: lazy(() =>
          import('components/Common/ModuleProfiling/ProfilingCreate')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: '/edit-profiling/:id',
        component: lazy(() =>
          import('components/Common/ModuleProfiling/ProfilingUpdate')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      {
        path: '/list-profiling',
        component: lazy(() =>
          import(
            'components/Common/ModuleProfiling/UsersListProfiling/UsersListProfiling'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // MODULO CRM LEAD
      {
        path: `${ConfigNameRoutes.crm}`,
        component: lazy(() => import('containers/Config/ModuleCrmLead/ListSelectCrm')),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.crm}/dashboard`,
            component: lazy(() => import('components/Common/ModuleCrmLead/dashboard/CrmDealsDashboard.js')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.crm}/daily-task`,
            component: lazy(() => import('components/Common/ModuleCrmLead/Task/ListTask')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.crm}/deals`,
            component: lazy(() => import('components/Common/ModuleCrmLead/Deals/ListDeals')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.crm}/flow`,
            component: lazy(() => import('components/Common/ModuleCrmLead/Flow/FlowCrm')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.crm}/flow-comercial/:comercial_id`,
            component: lazy(() => import('components/Common/ModuleFlow/ComercialFlow')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.crm}/all-deals`,
            component: lazy(() => import('components/Common/ModuleCrmLead/Deals/ListAllDeals.js')),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.crm}/reports`,
            component: lazy(() => import('components/Common/ModuleCrmLead/reports/CrmReports.js')),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },

      // MODULO CONFIG FLOW
      {
        path: `${ConfigNameRoutes.configFlow}`,
        component: lazy(() =>
          import('containers/Config/ModuleConfigFlow/ListSelectFlow')
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.configFlow}/stages`,
            component: lazy(() =>
              import('components/Common/ModuleConfigFlow/Stages/ListStages')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configFlow}/flows`,
            component: lazy(() =>
              import('components/Common/ModuleConfigFlow/Flows/ListFlows')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.configFlow}/actions`,
            component: lazy(() =>
              import('components/Common/ModuleConfigFlow/Actions/ListActions')
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },
      {
        path: '/create-steps',
        component: lazy(() =>
          import('components/Common/ModuleFlow/CreateStepFlow/CreateStepFlow')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: '/create-steps/:id_funnel',
        component: lazy(() =>
          import('components/Common/ModuleFlow/CreateStepFlow/CreateStepFlow')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // cashier
      {
        path: '/cashier',
        component: lazy(() =>
          import('components/Common/ModuleCashierComercial/Cashier')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: '/cashier/invoices',
        component: lazy(() =>
          import(
            'components/Common/ModuleCashierComercial/IncomeList/IncomeList'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // Module Corporative Agreements
      {
        path: `${ConfigNameRoutes.corporative}`,
        component: lazy(() =>
          import('components/Common/ModuleCorporative/AgreementsList')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.corporative}/:action`,
        component: lazy(() =>
          import('components/Common/ModuleCorporative/MeshCreation')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.corporative}/:uuid/:option`,
        component: lazy(() =>
          import('components/Common/ModuleCorporative/AgreementsDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.companies}`,
        component: lazy(() =>
          import('components/Common/ModuleCorporative/CompaniesList')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // Campaigns
      {
        path: `${ConfigNameRoutes.campaigns}`,
        component: lazy(() =>
          import('components/Common/ModuleCampaigns/ListCampaigns')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: '/offerts',
        component: lazy(() =>
          import('components/Common/ActivesOferts/TodayAndTomorrowOfert')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.campaigns}/:uuid`,
        component: lazy(() =>
          import('components/Common/ModuleCampaigns/CampaignMesh')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // customer
      {
        path: `${ConfigNameRoutes.detailCustomer}`,
        component: lazy(() =>
          import('pages/DetailAfiliatePage/DetailCustomerPage.js')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.detailCustomerComercial}`,
        component: lazy(() =>
          import('pages/DetailAfiliatePage/DetailCustomerPage.js')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.detailCustomerComercialItem}`,
        component: lazy(() =>
          import('pages/DetailAfiliatePage/DetailCustomerPage.js')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // DEALS
      {
        path: `${ConfigNameRoutes.deals}`,
        component: lazy(() =>
          import('components/Common/ModuleDeals/DealsList/DealsList')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.dealsDetail}/:route/:deal_uuid`,
        component: lazy(() =>
          import('components/Common/ModuleDeals/DealsDetail/DealsDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // LEADS
      {
        path: `${ConfigNameRoutes.leads}`,
        component: lazy(() => import('components/Common/ModuleLeads/Leads')),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.leads}/:lead_id`,
        component: lazy(() =>
          import('components/Common/ModuleLeads/LeadDetail/LeadDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.leads}/:lead_id/:is_commercial`,
        component: lazy(() =>
          import('components/Common/ModuleLeads/LeadDetail/LeadDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.leads}/:lead_id/:is_commercial/:item`,
        component: lazy(() =>
          import('components/Common/ModuleLeads/LeadDetail/LeadDetail')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // module-novelty
      {
        path: `${ConfigNameRoutes.novelty}`,
        component: lazy(() =>
          import('components/Common/ModuleNovelties/ListNovelties')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },

      // module kits
      {
        path: `${ConfigNameRoutes.kits}`,
        component: lazy(() =>
          import('components/Common/ModuleKits/ListKits/ListKits')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.kits}/:action`,
        component: lazy(() =>
          import('components/Common/ModuleKits/KitCreation/KitCreation')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.kits}/:uuid`,
        component: lazy(() =>
          import('components/Common/ModuleKits/KitCreation/KitCreation')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.kits}/products/:uuid`,
        component: lazy(() =>
          import('components/Common/ModuleKits/UpdateKitPrices/UpdateKitPrices')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // module chat

      {
        path: `${ConfigNameRoutes.chat}`,
        component: lazy(() =>
          import('components/Common/ModuleChat/Dashboard/Dashboard')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.chat}/:channel`,
        component: lazy(() =>
          import('components/Common/ModuleChat/Dashboard/Dashboard')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      // Module list
      {
        path: `${ConfigNameRoutes.generalList}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/ListGeneral')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.generalListVirtual}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/ListGeneralVirtual')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.generalListVirtualUser}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/ListGeneralActiveUser')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.generalListVirtualActive}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/ListGeneralActive.js')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.generalListNotCarterized}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/ListGeneralNotCarterized')
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.listAgreementLinesNotTokenized}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleLists/Views/AgreementLinesnotTokenized'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.customersList}`,
        component: lazy(() =>
          import('components/Common/ModuleLists/Views/CustomersList')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: `${ConfigNameRoutes.ranking}`,
        component: lazy(() =>
          import('components/Common/ModuleRanking/ListRanking')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // Contratos no firmados
      {
        path: `${ConfigNameRoutes.unsignedContracs}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleUnsignedContracts/UnsignedContractsList'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // Metodos de Pago no Validados
      {
        path: `${ConfigNameRoutes.noValidatedPaymentMethods}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleNoValidatedPaymentMethods/NoValidatedPaymentMethods'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // Lista Corporativo
      {
        path: `${ConfigNameRoutes.listCorporate}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleListCorporate/ModuleListCorporate'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // Carterizacion masiva
      {
        path: `${ConfigNameRoutes.massiveCarterization}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleMasiveCarterization/MasiveCarterization'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // Reportes
      {
        path: `${ConfigNameRoutes.reportsByVenue}`,
        component: lazy(() =>
          import('components/Common/ModuleReports/Reports')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // Carterizacion masiva
      {
        path: `${ConfigNameRoutes.massiveCarterization}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleMasiveCarterization/MasiveCarterization'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // referrals
      {
        path: `${ConfigNameRoutes.referralsNotAplicateList}`,
        component: lazy(() =>
          import(
            'components/Common/ManageDetailAfiliate/UserComercialComponents/referrals/ReferralsNotAplicateList'
          )
        ),
        title: 'Lista de referidos sin aplicar',
        exact: true,
        private: true,
        requiredPermissions: true
      },
      // Crm Configuration
      {
        path: `${ConfigNameRoutes.crmConfiguration}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleConfigCrm/ModuleConfigCrm.js'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.crmConfiguration}/type-lost`,
            component: lazy(() =>
              import(
                'components/Common/ModuleCrmConfig/CrmTypesLost.js'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
          {
            path: `${ConfigNameRoutes.crmConfiguration}/config-by-brand`,
            component: lazy(() =>
              import(
                'components/Common/ModuleCrmConfig/CrmConfigByBrand.js'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          }
        ]
      },
      // Commercial statistics
      {
        path: `${ConfigNameRoutes.CommercialStatistics}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleCommercialStatistics/CommercialStatistics.js'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // Profiling close list
      {
        path: `${ConfigNameRoutes.CreateProfilingCloseList}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleProfiling/ProfilingCloseList/CreateProfilingCloseList'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.UpdateProfilingCloseList}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleProfiling/ProfilingCloseList/UpdateProfilingCloseList'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // Profiling close list
      {
        path: `${ConfigNameRoutes.CreateProfilingCloseList}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleProfiling/ProfilingCloseList/CreateProfilingCloseList'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.UpdateProfilingCloseList}`,
        component: lazy(() =>
          import(
            'components/Common/ModuleProfiling/ProfilingCloseList/UpdateProfilingCloseList'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // promotions
      {
        path: `${ConfigNameRoutes.promotionServices}`,
        component: lazy(() =>
          import(
            'components/Common/ModulePromotions/Promotions/Promotions'
          )
        ),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      // Notificaciones push
      {
        path: `${ConfigNameRoutes.NotificationPush}`,
        component: lazy(() =>
          import('components/Common/ModuleNotifications/NotificationPush/NotificationPush')
        ),
        exact: true,
        private: true,
        requiredPermissions: true
      },
      {
        path: '/reports-crm',
        component: lazy(() => import('components/Common/ModuleCrmLead/reports/CrmReports.js')),
        exact: true,
        private: true,
        requiredPermissions: false
      },
      {
        path: `${ConfigNameRoutes.dashboardTrafic}`,
        component: lazy(() => import('components/Common/DashboardTrafic/DashboardTrafic.js')),
        exact: true,
        private: true,
        requiredPermissions: true
      },

      // Challenge Configuration
      {
        path: `${ConfigNameRoutes.ChallengeConfig}`,
        component: lazy(() =>
          import(
            'containers/Config/ModuleChallenge/ModuleConfigChallenge.js'
          )
        ),
        exact: false,
        private: true,
        requiredPermissions: true,
        routes: [
          {
            path: `${ConfigNameRoutes.ChallengeConfig}/challenges`,
            component: lazy(() =>
              import(
                'components/Common/ModuleChallengeConfig/ChallengeList.js'
              )
            ),
            exact: true,
            private: true,
            requiredPermissions: true
          },
        ]
      },
    ]
  }
]
