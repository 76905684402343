import React, { useEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Swal from "sweetalert2";

const routes = ['/quotation-config'];
const ServiceWorkerWrapper = ({ children }) => {
  const intervalTime = 60000;

  useEffect(() => {
    const onServiceWorkerUpdate = (registration) => {
      if (registration.waiting) {
        // Mostrar alerta de actualización disponible
        Swal.fire({
          title: "Actualización disponible",
          text: "Una nueva versión de My Bodytech está disponible, actualiza ahora para disfrutar de las últimas características.",
          showCancelButton: routes.includes(window.location.pathname),
          cancelButtonText: "Ahora no",
          cancelButtonColor: "#959595",
          confirmButtonColor: "#CC5567",
          confirmButtonText: "Actualizar",
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // Si el usuario confirma, forzar la actualización
            registration.waiting.postMessage({ type: "SKIP_WAITING" });

            // Escuchar cambios de estado del Service Worker
            registration.waiting.addEventListener("statechange", (event) => {
              if (event.target.state === "activated") {
                window.location.reload();
              }
            });
          }
        });
      }
    };

    const checkForUpdate = () => {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: "CHECK_FOR_UPDATE" });
      }
    };

    if (process.env.NODE_ENV === "production") {
      serviceWorkerRegistration.register({
        onUpdate: onServiceWorkerUpdate,
        onSuccess: () => {
          const intervalId = setInterval(() => {
            checkForUpdate();
          }, intervalTime);

          return () => clearInterval(intervalId);
        },
      });

      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data.type === "CHECK_FOR_UPDATE") {
          navigator.serviceWorker.getRegistration().then((registration) => {
            if (registration) {
              registration.update();
            }
          });
        }
      });

      setInterval(() => {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({ type: "CHECK_FOR_UPDATE" });
        }
      }, intervalTime);
    }
  }, []);

  return children;
};

export default ServiceWorkerWrapper;
